import onDomReady from '../utils/onDomReady';

function removeActive(e) {
  for (let b = 0; b < e.length; b += 1) {
    e[b].classList.remove('active');
  }
}

const tabsFunc = () => {
  const tabsContainer = document.querySelectorAll('.tabsContainer');
  if (tabsContainer.length > 0) {
    for (let i = 0; i < tabsContainer.length; i += 1) {
      const tabs = tabsContainer[i].querySelectorAll("[data-toggle='tab']");

      for (let a = 0; a < tabs.length; a += 1) {
        tabs[a].addEventListener('click', function tabClick(e) {
          e.preventDefault();
          removeActive(tabsContainer[i].querySelectorAll("[data-toggle='tab']"));
          removeActive(tabsContainer[i].querySelectorAll('.tabsContent__pane'));
          tabsContainer[i].querySelector(this.hash).classList.add('active');
          this.classList.add('active');
        });
      }
    }
  }
  // activate tab on page load
  // eslint-disable-next-line no-restricted-globals
  const { hash } = location;
  const tab = document.querySelector(`.tabsContainer a[href="${hash}"]`);
  if (tab && hash) {
    tab.click();
  }
};

onDomReady(tabsFunc);

document.addEventListener('turbo:load', () => {
  tabsFunc();
});
